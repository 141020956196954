// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-konzerte-js": () => import("./../../../src/pages/konzerte.js" /* webpackChunkName: "component---src-pages-konzerte-js" */),
  "component---src-pages-mitglied-werden-js": () => import("./../../../src/pages/mitglied-werden.js" /* webpackChunkName: "component---src-pages-mitglied-werden-js" */),
  "component---src-pages-saison-2425-js": () => import("./../../../src/pages/saison-2425.js" /* webpackChunkName: "component---src-pages-saison-2425-js" */),
  "component---src-pages-ueber-gml-js": () => import("./../../../src/pages/ueber-gml.js" /* webpackChunkName: "component---src-pages-ueber-gml-js" */)
}

